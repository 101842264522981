<template>
  <div class="w-full h-full pt-5">
    <template>
      <div class="flex px-2">
        <h1 class="text-xl text-left font-extrabold mr-5">
          Compensation
        </h1>
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </template>
    <template>
      <Card class="px-3 py-2 mx-2 my-5">
        <h2 class="font-bold text-lg leading-5 text-darkPurple">
          Pay Benchmark
        </h2>
        <Card class="px-5 py-5 my-3 flex flex-col justify-center items-start">
          <div class="flex justify-start items-center gap-2 w-full">
            <h3 class="font-bold text-lg leading-5 text-romanSilver w-1/6">
              Compare By
            </h3>
            <div class="w-5/6 flex justify-between items-start gap-5 text-romanSilver">
              <Select placeholder="Location 1" class="w-1/2 text-romanSilver" />
              <Select placeholder="Location 2" class="w-1/2 text-romanSilver" />
            </div>
          </div>
          <Divider border-dash class="w-full mt-5" />
          <CardFooter reloadcard />
        </Card>
        <template v-if="!items">
          <div class="flex flex-col justify-center items-center gap-5 px-10 pb-20 w-full">
            <Icon size=""
              class-name="w-80 h-80"
              icon-name="comreview_illustration"
            />
            <p class="font-normal text-base leading-5 text-jet text-center w-3/6">
             A list of compensation reviews for your organisaton will be shown here. Start by adding one using the button below.
            </p>
            <div class="flex justify-start items-center gap-2">
              <Icon size="xs" icon-name="icon-plus" />
              <p class="font-semibold text-base leading-5 text-flame">
                Start New Review
              </p>
            </div>
          </div>
        </template>
        <template v-if="compareBenchmark === ''">
          <Table
            :headers="headers"
            :items="items"
            :has-number="false"
            class="w-full"
          >
            <template v-slot:item="{ item }">
              <div v-if="item.location" class="font-normal text-sm leading-5 tracking-widest">
                <span class="text-darkPurple">{{ item.data.location }}</span>
              </div>
              <div v-if="item.function" class="font-normal text-sm leading-5 tracking-widest">
                <span class="text-darkPurple">{{ item.data.function }}</span>
              </div>
              <div v-if="item.jobLevel" class="font-normal text-sm leading-5 tracking-widest">
                <span class="text-darkPurple">{{ item.data.jobLevel }}</span>
              </div>
              <div v-if="item.payGrade" class="font-normal text-sm leading-5 tracking-widest">
                 <span class="text-darkPurple">{{ item.data.payGrade }}</span>
              </div>
              <div v-if="item.designation" class="font-normal text-sm leading-5 tracking-widest">
                <span class="text-darkPurple">{{ item.data.designation }}</span>
              </div>
              <div v-if="item.lengthOfService" class="font-normal text-sm leading-5 tracking-widest">
                <span class="text-darkPurple">{{ item.data.lengthOfService }}</span>
              </div>
              <div v-if="item.performanceScore" class="font-normal text-sm leading-5 tracking-widest">
                <span class="text-darkPurple">{{ item.data.performanceScore }}</span>
              </div>
              <div v-if="item.gender" class="font-normal text-sm leading-5 tracking-widest">
                <span class="text-darkPurple">{{ item.data.gender }}</span>
              </div>
              <div v-if="item.employee" class="font-normal text-sm leading-5 tracking-widest">
                <span class="text-darkPurple">{{ item.data.employee }}</span>
              </div>
              <div v-if="item.manager" class="font-normal text-sm leading-5 tracking-widest">
                <span class="text-darkPurple">{{ item.data.manager }}</span>
              </div>
            </template>
          </Table>
        </template>
        <template v-if="compareBenchmark === 'location'">
          <Table
            :headers="headers1"
            :items="items1"
            :has-number="false"
            class="w-full"
          >
            <template v-slot:item="{ item }">
              <div v-if="item.location" class="font-normal text-sm leading-5 tracking-widest">
                <span class="text-darkPurple">{{ item.data.location }}</span>
              </div>
              <div v-if="item.function" class="font-normal text-sm leading-5 tracking-widest">
                <span class="text-darkPurple">{{ item.data.function }}</span>
              </div>
              <div v-if="item.employee" class="font-normal text-sm leading-5 tracking-widest">
                <span class="text-darkPurple">{{ item.data.employee }}</span>
              </div>
              <div v-if="item.jobLevel" class="font-normal text-sm leading-5 tracking-widest">
                <span class="text-darkPurple">{{ item.data.jobLevel }}</span>
              </div>
              <div v-if="item.payGrade" class="font-normal text-sm leading-5 tracking-widest">
                 <span class="text-darkPurple">{{ item.data.payGrade }}</span>
              </div>
              <div v-if="item.grossPay" class="font-normal text-sm leading-5 tracking-widest">
                <span class="text-darkPurple">{{ item.data.grossPay }}</span>
              </div>
              <div v-if="item.compaRatio" class="font-normal text-sm leading-5 tracking-widest">
                <span class="text-desire">{{ item.data.compaRatio }}</span>
              </div>
            </template>
          </Table>
        </template>
        <template v-if="compareBenchmark === 'employee'">
          <Table
            :headers="headers2"
            :items="items2"
            :has-number="false"
            class="w-full"
          >
            <template v-slot:item="{ item }">
              <div v-if="item.location" class="font-normal text-sm leading-5 tracking-widest">
                <span class="text-darkPurple">{{ item.data.location }}</span>
              </div>
              <div v-if="item.function" class="font-normal text-sm leading-5 tracking-widest">
                <span class="text-darkPurple">{{ item.data.function }}</span>
              </div>
              <div v-if="item.employee" class="font-normal text-sm leading-5 tracking-widest">
                <div class="flex flex-col justify-start items-start">
                  <span class="text-darkPurple">{{ item.data.employee.name }}</span>
                  <span class="text-romanSilver">{{ item.data.employee.designation }}</span>
                </div>
              </div>
              <div v-if="item.jobLevel" class="font-normal text-sm leading-5 tracking-widest">
                <span class="text-darkPurple">{{ item.data.jobLevel }}</span>
              </div>
              <div v-if="item.payGrade" class="font-normal text-sm leading-5 tracking-widest">
                 <span class="text-darkPurple">{{ item.data.payGrade }}</span>
              </div>
              <div v-if="item.grossPay" class="font-normal text-sm leading-5 tracking-widest">
                <span class="text-darkPurple">{{ item.data.grossPay }}</span>
              </div>
              <div v-if="item.compaRatio" class="font-normal text-sm leading-5 tracking-widest">
                <span class="text-desire">{{ item.data.compaRatio }}</span>
              </div>
            </template>
          </Table>
        </template>
      </Card>
    </template>
  </div>
</template>

<script>
  import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
  import Table from "@scelloo/cloudenly-ui/src/components/table";
  import Card from "@scelloo/cloudenly-ui/src/components/card";
  import Select from "@scelloo/cloudenly-ui/src/components/select";
  import CardFooter from "@/components/CardFooter";
  import Divider from "@/components/divider";
  import Icon from "@/components/Icon";

  export default {
    name: 'CompensationPayBenchMark',
    components: {
      Breadcrumb,
      Table,
      Card,
      Select,
      Icon,
      Divider,
      CardFooter,
    },
    data(){
      return {
        compareBenchmark: 'employee',
        breadcrumbs: [
          {
            disabled: false,
            text: "Compensation",
            href: "compensation",
            id: "Compensation"
          },
          {
            disabled: false,
            text: "Pay Benchmark",
            href: "pay-benchmark",
            id: "PayBenchmark"
          }
        ],
        headers: [
          { title: "location", value: "location" },
          { title: "function", value: "function" },
          { title: "job level", value: "jobLevel" },
          { title: "pay grade", value: "payGrade" },
          { title: "designation", value: "designation" },
          { title: "length of service", value: "lengthOfService" },
          { title: "performance score", value: "performanceScore" },
          { title: "gender", value: "gender" },
          { title: "employee", value: "employee" },
          { title: "manager", value: "manager" },
        ],
        items: [
          {
            location: "Lagos, Nigeria",
            function: 'Human Resources',
            jobLevel: 'Level 1',
            payGrade: "Grade 1",
            designation: 'HR',
            lengthOfService: "2 - 5 Years",
            performanceScore: '4 - 5',
            gender: 'Male',
            employee: 'Okeke Udo',
            manager: 'Okeke Udo',
          },
          {
            location: "Lagos, Nigeria",
            function: 'Human Resources',
            jobLevel: 'Level 1',
            payGrade: "Grade 1",
            designation: 'HR',
            lengthOfService: "2 - 5 Years",
            performanceScore: '4 - 5',
            gender: 'Male',
            employee: 'Okeke Udo',
            manager: 'Okeke Udo',
          },
        ],
        headers1: [
          { title: "location", value: "location" },
          { title: "function", value: "function" },
          { title: "employee", value: "employee" },
          { title: "job level", value: "jobLevel" },
          { title: "pay grade", value: "payGrade" },
          { title: "gross pay", value: "grossPay" },
          { title: "compa-ratio", value: "compaRatio" },
        ],
        items1: [
          {
            location: "Abuja, Nigeria",
            function: 'All',
            employee: 'All',
            jobLevel: '3.00',
            payGrade: "2",
            grossPay: 'N2,750,000',
            compaRatio: '73%',
          },
          {
            location: "Abuja, Nigeria",
            function: 'All',
            employee: 'All',
            jobLevel: '3.00',
            payGrade: "2",
            grossPay: 'N2,750,000',
            compaRatio: '73%',
          },
        ],
        headers2: [
          { title: "location", value: "location" },
          { title: "function", value: "function" },
          { title: "employee", value: "employee" },
          { title: "job level", value: "jobLevel" },
          { title: "pay grade", value: "payGrade" },
          { title: "gross pay", value: "grossPay" },
          { title: "compa-ratio", value: "compaRatio" },
        ],
        items2: [
          {
            location: "Abuja, Nigeria",
            function: 'All',
            employee: { name: 'Uwandu Uwaoma', designation: 'BE Engineer (F)'},
            jobLevel: '3.00',
            payGrade: "2",
            grossPay: 'N2,750,000',
            compaRatio: '73%',
          },
          {
            location: "Abuja, Nigeria",
            function: 'All',
            employee: { name: 'Uwandu Uwaoma', designation: 'BE Engineer (F)'},
            jobLevel: '3.00',
            payGrade: "2",
            grossPay: 'N2,750,000',
            compaRatio: '73%',
          },
        ],
      }
    },
    methods: {
      toggleViewPayGrade(){
        this.$refs.viewPayGrade.toggle();
      }
    }
  }
</script>

<style>
 .dropdownMenu {
   margin-right: 30px !important;
 }
 .button-style {
    font-weight: 600 !important;
    font-size: 16px !important;
    font-size: 16px !important;
    line-height: 120% !important;
    padding: 10px 40px !important;
 }
</style>
